<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6" 
                width="500"
            >  
                <v-app-bar
                    flat
                    color="primary"
                >
                    <v-toolbar-title class="title white--text pl-0">
                        REQUIRED DOCUMENTS
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"                   
                >
                    <v-col
                        cols="12"                        
                    >
                        Please upload a scanned copy of the required documents
                        in the appropriate fields below
                    </v-col>
                </v-row>
            </v-card>
        </v-row>

        <v-row
            v-for="document in documents"
            :key="document.label"
        >
            <upload-files 
                v-bind:fileType="document.type"
                v-bind:fileLabel="document.label"
            ></upload-files>
        </v-row>
        
    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import UploadFiles from "./UploadFiles";

export default {
    name: 'Documents',

    components: {
        Layout,
        UploadFiles,
        
    },    

    data: () => ({        
        documents: [
            {
                label: 'Birth Certificate', 
                placeholder: 'Upload your birth certificate', 
                type: 'birth_certificate',
                ref: 'birth_certificate',
            },
            {
                label: 'Immunization Card', 
                placeholder: 'Upload your immunization card', 
                type: 'immunization_card',
                ref: 'immunization_card',
            },
            {
                label: 'SEA Slip', 
                placeholder: 'Upload your SEA results slip', 
                type: 'sea_slip',
                ref: 'sea_slip',
            },
            {
                label: 'Passport Photo', 
                placeholder: 'Upload Passport Photo', 
                type: 'photo',
                ref: 'picture',
            },
        ],      
        
    }),
    
}
</script>